import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.css"
import "./layout.css"
import Header from "./header"
import styled from "styled-components"

const Footer = styled.footer`
  padding: 20px;
  width: 100%;
  text-align: center;
  background-color: #121416;
  color: white;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer
        style={{
          textAlign: "center",
          fontFamily: "Nunito Sans",
          marginTop: "50px",
        }}
      >
        Made with{" "}
        <span role="img" aria-label="heart">
          ❤️
        </span>{" "}
        by
        {` `}
        <a
          style={{
            color: "white",
            fontWeight: 800,
            textDecoration: "none",
          }}
          href="https://www.ondelta.io/"
        >
          OnDelta
        </a>{" "}
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
