import PropTypes from "prop-types"
import { H3, P } from "./utils/Typography"
import React, { useState } from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div>
      <Navbar style={{ backgroundColor: "#121416" }} dark expand="md">
        <NavbarBrand href="/">
          <H3 style={{ margin: "0 15px" }}>JGR</H3>
        </NavbarBrand>
        <NavbarToggler
          onClick={toggle}
          style={{ outline: "none", color: "white", border: "none" }}
        />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar style={{ marginTop: "10px" }}>
            <NavItem>
              <NavLink
                href="/postJob"
                style={{ color: "white", textDecoration: "none" }}
              >
                <P>Post a Job</P>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink style={{ color: "white" }} href="/blog/">
                <P>Blog</P>
              </NavLink>
            </NavItem>
            <NavItem>
              <span style={{ fontFamily: "Nunito Sans, sans-serif" }}>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret style={{ color: "white" }}>
                    Socials
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      style={{ color: "#121416" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/jobsgoremote/"
                    >
                      Facebook
                    </DropdownItem>
                    <DropdownItem
                      style={{ color: "#121416" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/jobsgoremote"
                    >
                      Twitter
                    </DropdownItem>
                    <DropdownItem
                      style={{ color: "#121416" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/company/jobsgoremote/"
                    >
                      LinkedIn
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </span>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
