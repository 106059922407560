import styled from "styled-components"
import "../layout.css"

export const D1 = styled.h1`
  font-family: "Nunito Sans", sans-serif;
  font-weight: ${props => props.fontWeight || 800};
  font-size: 60px;
`

export const D2 = styled.h2`
  font-family: "Nunito Sans", sans-serif;
  font-size: 50px;
  font-weight: ${props => props.fontWeight || 800};
  line-height: 1;
  text-align: ${props => props.textAlign || "left"};
`

export const D3 = styled.h3`
  font-family: "Nunito Sans", sans-serif;
  font-size: 30px;
  font-weight: ${props => props.fontWeight || 400};
  line-height: 1.5;
  text-align: ${props => props.textAlign || "left"};
  margin: ${props => props.margin};
`

export const D4 = styled.h3`
  font-family: "Nunito Sans", sans-serif;
  font-size: 25px;
  font-weight: ${props => props.fontWeight || 400};
  line-height: 1.5;
  text-align: ${props => props.textAlign || "left"};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`

export const H1 = styled.h1`
  font-family: "Nunito Sans", sans-serif;
  text-align: ${props => props.textAlign || "left"};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  font-weight: ${props => props.fontWeight};
`

export const H2 = styled.h2`
  font-family: "Nunito Sans", sans-serif;
  text-align: ${props => props.textAlign || "left"};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  font-weight: ${props => props.fontWeight};
`

export const H3 = styled.h3`
  font-weight: ${props => props.fontWeight || 800};
  font-family: "Nunito Sans", sans-serif;
  text-align: ${props => props.textAlign || "left"};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`
export const H4 = styled.h4`
  font-size: 20px;
  font-weight: ${props => props.fontWeight || 600};
  font-family: "Nunito Sans", sans-serif;
  text-align: ${props => props.textAlign || "left"};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`
export const H5 = styled.h5`
  font-weight: ${props => props.fontWeight || 600};
  font-family: "Nunito Sans", sans-serif;
  line-height: 1.5;
  text-align: ${props => props.textAlign || "left"};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`

export const P = styled.p`
  font-family: "Nunito Sans", sans-serif;
  text-align: ${props => props.textAlign || "left"};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  font-weight: ${props => props.fontWeight};
`
